const DrawerList = [
  {
    name: "Dashboard",
    icon: "Dashboard",
    page: "Dashboard"
  },{
    name: "Inbox",
    icon: "Inbox",
    page: "Inbox",
  },{
    name: "Documents",
    icon: "Documents",
    page: "Documents",
  },
  // {
  //   name: "SafeForm Lite",
  //   page: "SafeForm Lite",
  //   icon: "SafeForm",
  // },
  {
    name: "Manage Users",
    icon: "People",
    page: "Users",
  },
  {
    name: "Signatures",
    icon: "Signature",
    page: "Signatures",
  },
  // {
  //   name: "Settings",
  //   page: "Settings",
  //   icon: "Settings",
  // },
  {
    name: "NotarizeIT",
    // page: "SafeForm Lite",
    icon: "notarizeIt",
    text: "COMING SOON"
  },
];



export default DrawerList;