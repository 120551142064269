import React from "react";
import { DrawerIconProps } from "@src/components/icon/interface";
import Dashboard from "./dashboard.svg";
import Document from "./document.svg";
import Inbox from "./inbox.svg";
import Logout from "./logout.svg";
import People from "./people.svg";
import SafeForm from "./safeform.svg";
import Settings from "./settings.svg";
import Signature from "./signature.svg";
import colors from "@assets/colors";
import NotarizeIt from "./notarizeIt.svg"

const DrawerIcon = (props: DrawerIconProps) => {
  const { name, isActive, width, height } = props;
  const fill = isActive ? colors.blue : colors.white;

  switch (name) {
  case "Dashboard":
    return <Dashboard fill={fill} width={width || 22} height={height || 20} />;
  case "Documents":
    return <Document fill={fill} width={width || 19} height={height || 22} />;
  case "Inbox":
    return <Inbox fill={fill} width={width || 20} height={height || 16} />;
  case "Logout":
    return <Logout fill={fill} width={width || 20} height={height || 20.94} />;
  case "People":
    return <People fill={fill} width={width || 23} height={height || 21} />;
  case "SafeForm":
    return <SafeForm fill={fill} width={width || 20} height={height || 22} />;
  case "Settings":
    return <Settings fill={fill} width={width || 24} height={height || 24} />;
  case "Signature":
    return <Signature fill={fill} width={width || 21} height={height || 21} />;
  case "notarizeIt":
    return <NotarizeIt fill={fill} width={width || 21} height={height || 21} />;
  }
};

export default DrawerIcon;
